import React from "react"
import { Select, Box, Label } from "theme-ui"

export default function OrderBy({
  variant,
  setArticleFilterSettings,
  articleFilterSettings,
  value,
}) {
  return (
    <Box sx={variant.orderByContainer} className="orderByContainer">
      <Label sx={variant.filterLabel}>Order By</Label>
      <Select
        value={value}
        onChange={e => {
          setArticleFilterSettings({
            ...articleFilterSettings,
            order: e.target.value,
          })
        }}
      >
        <option value="desc">DESC</option>
        <option value="asc">ASC</option>
      </Select>
    </Box>
  )
}
