// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Box, Heading } from 'theme-ui'
import ArticleMenu from '../components/Articles/ArticleMenu'
import PageHero from '../components/Heros/PageHero'
// ?===================================
// ?========  Style Imports  ==========
// ?===================================

// ?===================================
// ?======  Template Component  =======
// ?===================================

const ArticleMenuTemplate = (props) => {
  // ?==========================
  // ?====== Destructing  ======
  // ?==========================

  const { pageContext, data, location } = props

  // ?===================================================
  // ?==============   ON/OFF MOUNTING   ================
  // ?===================================================

  const articleDataSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'team':
        return 'allTeam'

      case 'press':
        return 'allPress'

      case 'general':
        return 'allGeneral'

      case 'policies':
        return 'allPolicies'

      case 'blog':
        return 'allBlog'

      default:
        break
    }
  }

  const heroImageSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'blog':
        return 'blog-pagehero'

      case 'team':
        return 'team-pagehero'

      case 'press':
        return 'press-pagehero'

      case 'general':
        return 'general-pagehero'

      case 'policies':
        return 'policies-pagehero'

      default:
        break
    }
  }

  const heroTitleSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'blog':
        return 'Our Blog'

      case 'team':
        return 'Meet The Team'

      case 'press':
        return 'In The Press'

      case 'general':
        return 'Our Content'

      case 'policies':
        return 'Our Policies'

      default:
        break
    }
  }

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================

  return (
    <Box sx={styles.container}>
      <PageHero
        imageID={heroImageSelector(pageContext.pageObjectKey)}
        sx={styles.pageHero}
        title={heroTitleSelector(pageContext.pageObjectKey)}
      />

      {data[articleDataSelector(pageContext.pageObjectKey)] ? (
        <ArticleMenu
          articleData={data[articleDataSelector(pageContext.pageObjectKey)]}
          pageObjectKey={pageContext.pageObjectKey}
          blogTags={data.blogTags.tags}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            minHeight: '30vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Heading
            as='h2'
            sx={{ fontSize: '2rem', textTransform: 'uppercase' }}
          >
            No {pageContext.pageTitle} At This Time
          </Heading>
        </Box>
      )}
    </Box>
  )
}

export default ArticleMenuTemplate

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column',
  },

  pageHero: {
    height: '50vh',
  },
}

// ?=====================================
// ?======= GraphQl page queries ========
// ?=====================================

export const query = graphql`
  query articleTemplateQuery {
    blogTags {
      tags
    }
    allBlog(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          author
          articleType
          body
          bodyHTML
          bodyPlainText
          createdAt
          description
          id
          media {
            name
            description
            cloudinaryId
          }
          tags
          title
          subtitle
          readingTimeText
          readingTime
          stats {
            rants
            raves
            shares
            followers
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
    allPress(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
    allTeam {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
    allPolicies {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
    allGeneral(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          subtitle
          title
          body
          bodyHTML
          bodyPlainText
          readingTime
          readingTimeText
          description
          createdAt
          articleType
          media {
            cloudinaryId
            description
            name
          }
          ctaLink {
            title
            url
          }
        }
      }
    }
  }
`
