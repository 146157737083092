export default {
  // ?============================
  // ?======  Containers =========
  // ?============================

  container: {
    padding: '1rem',
    display: ['', '', '', 'flex'],
  },

  // ?============================
  // ?========  Filters   ========
  // ?============================

  filters: {
    display: 'flex',
    padding: ['0rem', '', '', '0.5rem'],
    flexDirection: ['', '', '', 'column'],
    minWidth: '250px',
    borderRight: ['', '', '', '1px solid'],
    marginRight: ['', '', '', '1rem'],
    flexWrap: 'wrap',
  },
  filtersTitle: {
    width: '100%',
    padding: '0.5rem',
    marginBottom: '0.5rem',
  },

  filterLabel: {
    marginBottom: '0.25rem',
    padding: '0.25rem',
    fontSize: '0.8rem',
  },

  orderByContainer: {
    minWidth: '100px',
    margin: '0.5rem',
  },
  searchContainer: {
    flexGrow: ['1', '', '', '0'],
    margin: ['0.5rem'],
    svg: {
      position: 'absolute',
      right: '0.75rem',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  searchInputContainer: {
    position: 'relative',
  },

  sortByContainer: {
    margin: '0.5rem',
  },

  // ?============================
  // ?======  Article Boxes ======
  // ?============================

  boxesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'stretch',
    width: '100%',
    maxWidth: '1000px',
    margin: '0rem auto',
  },
  box: {
    width: ['100%', 'calc(50% - 1rem)', 'calc(33.3% - 1rem)'],
    padding: '0rem',
    border: 'solid 1px black',
    margin: ['1rem 0rem', '0.5rem', '0.5rem'],
    position: 'relative',
    overflow: 'hidden',
    flexGrow: '1',
    a: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    ':hover': {
      '.textContent': {
        // opacity: "0.8",
        left: '0px',
      },
      img: {
        transform: 'translate(-50%, -50%) scale(1.1)',
        filter: 'brightness(0.5)',
      },
    },
  },

  // ?============================
  // ?======  Image Content =======
  // ?============================

  imageContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  imageFill: {
    paddingBottom: '70%',
  },
  image: {
    transition: 'all ease-in-out 0.5s',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  // ?============================
  // ?======  Text Content =======
  // ?============================
  textContent: {
    padding: '1rem',
    fontSize: '1rem',
    borderTop: '1px solid lightgrey',
    transition: 'all ease-in-out 0.5s',
    backgroundColor: 'transparent',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '0rem',
    left: '-1000px',
    width: '100%',
  },
  // name: {
  //   fontSize:'1.5em',
  //   marginBottom: '1rem',
  // },
  title: {
    fontSize: '2em',
    marginBottom: '1rem',
    color: 'white',
    lineHeight: '1.5',
  },
  subtitle: {
    fontSize: '1.25em',
    marginBottom: '1rem',
    opacity: '0.7',
    fontWeight: '400',
    color: 'white',

    lineHeight: '1.5',
  },
  text: {
    fontSize: '0.9em',
    marginBottom: '1rem',
    flexGrow: '1',
    lineHeight: '1.25',
    display: 'none',
  },

  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: '1px solid',
    opacity: '0.6',
    padding: '0.5rem 0rem 0rem',
    margin: 'auto 0rem 0rem',
    svg: {
      marginRight: '0.5rem',
    },
  },

  readingTime: {
    fontSize: '0.8em',
    margin: '0rem 1rem 0rem 0rem',
    padding: '0.5rem 0rem',
    svg: {
      marginRight: '0.5rem',
    },
  },

  createdAt: {
    fontSize: '0.8em',
    margin: '0rem 1rem 0rem 0rem',
    padding: '0.5rem 0rem',
    svg: {
      marginRight: '0.5rem',
    },
  },

  // ?============================
  // ?========  Buttons  =========
  // ?============================

  ctaContainer: {
    a: {
      display: 'flex',
      variant: 'buttons.primary',
    },
  },
}
